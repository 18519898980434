 :root {
  --main: #6d00be;
  --background: #fcedff;
  --background-linear:linear-gradient(135deg, #a700ff 0%, #00189a 100%);
 }
 
 body {
   background-color: rgb(254, 252, 247);
 }
 
 #root {
   margin: auto;
   overflow-x: hidden;
   overflow-y: auto;
   background-color: var(--background);
   min-height: 100vh;
   position: relative;
 }

 #root::-webkit-scrollbar {
  display: none;
}

 iframe {
   display: none;
 }

 @media (max-width: 768px) {
  #root {
    max-width: 100% !important;
  }
 }