// design system
// Colors
// Common
$white: #ffffff;
$red: #ff2e00;
$orange: #ff8314;
$orange-200: rgba(255, 131, 20, 0.2);
$yellow: #ffdf36;
$purple: #4e2ecf;
$--main-color: #1b29fa;
$violet: #6d00be;

// Color #1
$color1-main: #36ffb5;
$color1-bg-primary1: #06052a;
$color1-bg-primary2: #141432;
$color1-bg-sub1: #1b1a43;
$color1-bg-sub2: #211c48;
$color1-bg-sub3: #211c48;
$color1-text1: #141432;
$color1-blue: #09d1fe;
$color1-gray0: #616161;
$color1-gray1: #737373;
$color1-gray2: #acacac;
$color1-gray3: #d9d9d9;
$color1-gray4: #f5f5f5;
$color1-orange: #ff9d46;

// Color #2
$color2-main: #e26b0f;
$color2-sub6: #00bf13;
$color2-sub2: #141432;
$color2-text2: #141432;
$color2-gray1: #f8f8f8;
$color2-gray2: #f1f1f1;
$color2-gray3: #fbfbfb;

// Color #3
$color3-main: #1b29fa;
$color3-secondary: #ffba52;
$color3-blue: #5479bb;
$color3-blue-200: #f2f7ff;
$color3-text: #222222;
$color3-red: #ed3c37;
$color3-gray: #f7f7f7;

// customize
$primary: $color3-main;
$secondary: $color3-secondary;
$light: $color2-gray1;

$font-family-base: "Barlow";
$line-height-base: 1.25;
$font-size-base: 1rem;

$body-color: $color3-text;
$body-bg-color: $white;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// additional class
//reset
p {
  margin: 0;
  padding: 0;
}

// fonts
.fw-semibold {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-7 {
  font-size: 0.9375rem;
}

.fs-8 {
  font-size: 0.875rem;
}

// colors
.bg-blue {
  background-color: $color3-blue !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-orange-200 {
  background-color: $orange-200 !important;
}

.bg-gray {
  background-color: $white !important;
}

.bg-success {
  background-color: rgba(0, 191, 19, 0.1) !important;
  color: $color2-sub6 !important;
}

.bg-warning {
  background-color: rgba(255, 131, 20, 0.1) !important;
  color: $orange !important;
}

.bg-error {
  background-color: rgba(255, 46, 0, 0.1) !important;
  color: $red;
}

.text-blue {
  color: $color3-blue !important;
}

.text-gray {
  color: $color1-gray2 !important;
}

.text-orange {
  color: $orange !important;
}

// components
.card {
  background: $white !important;
  border: 1px solid rgba(0, 0, 0, 0.04) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  border-radius: 6px !important;
}

.card-header {
  background: none;
  padding: 20px 9px !important;
  border: none;
}

.img-rounded {
  width: 40px;
  height: 40px;
  background: $color2-gray3;
  border: 1px solid $color1-gray3;
  box-sizing: border-box;
  border-radius: 53.3333px;
  &--vertical {
    width: 76px;
    height: 76px;
  }
}

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.badge {
  position: static !important;
}

.border-top-blue {
  border-top-color: $color3-blue-200;
}

.btn-outline-blue {
  background-color: $color3-blue-200;
}

// utils
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.text-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Animation
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft !important;
}

.bg-img-earth {
  background-image: url("/assets/imagesHome/bg-branch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-earth-full {
  background-image: url("/assets/imagesHome/bg-branch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.pointer {
  cursor: pointer;
}
.relative-parent {
  position: relative;
}
#body-root {
  background-color: #9195a3;
}

.violet_button {
  background-color: $violet;
}
.custom__button {
  height: 50px;
  text-align: center;
  width: 100%;
  border-radius: 30px;
  border-color: transparent;
}
.mgt-8 {
  margin-top: 8px !important;
}
.ant-input {
  height: 45px;
  border-radius: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  color: #495057 !important;
}
.login-container {
  .login-center {
    background: unset !important;
    margin-top: 15px;
    .login-image {
      width: 100%;
      text-align: center;
      img {
        position: unset;
        width: 100px;
        height: 100px;
        max-width: unset;
        border-radius: 10px;
      }
    }
  }
}
.login-container .login__input__icon img {
  z-index: 2;
  max-width: 28px;
  position: absolute !important;
  top: 50%;
  left: 12px;
  width: 12px;
  height: auto;
  transform: translateY(-50%);
}
.login-container .login__input__icon .login__input__icon-phone {
  img {
    left: 0;
  }
  span {
    padding-left: 13px;
  }
}
.login-center .login__input-phone,
#login_password input,
.register-container.small form .ant-input-password input {
  padding-left: 32px !important;
}
#root {
  background: #fcedff;
  background: -moz-linear-gradient(top, #fcedff 0%, #ffffff 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fcedff), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #fcedff 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #fcedff 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #fcedff 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #fcedff 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcedff', endColorstr='#ffffff', GradientType=0);
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% 100%;
}
.ai-c {
  align-items: center;
}
.wrap{
  flex-wrap: wrap;
}
.text-mute {
  opacity: 0.7;
}
.j-sb {
  justify-content: space-between;
}
.pdh-15 {
  padding: 0 15px;
}
.pd-15 {
  padding: 15px !important;
}
.text-right {
  text-align: right;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.pdv-15 {
  padding: 15px 0;
}
.mg-0{
  margin: 0;
}
.po-r{
  position: relative;
}
.po-a{
  position: absolute;
}
.h-100{
  height: 100%;
}
.mgr-5 {
  margin-right: 5px;
}
.text-secondary {
  color: #666666 !important;
}
.text-white {
  h5,h1,h2,h3,h4,span {
    color: white;
  }
}
.bold{
  font-weight: bold;
}
.mt-hd {
  margin-top: 54px !important;
}
.pd-0.pd{
  padding: 0 !important;
}
.btn-ctm.btn-circle {
  height: 54px;
  width: 54px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  font-size: 25px;
  justify-content: center;
}
.text-medium {
  font-size: 30px;
}
.loading-fixed{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    background-image: url('/assets/images/loading.svg');
    background-size: 55px 70px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.514);
    z-index: 999;
  }
}
.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  text-decoration: none !important;
  background: #6d00be !important;
  border-color: none !important;
}

// Pages
@import "../Page/Branch/index.scss";
@import "../Page/ForgotPass/forgotPass.scss";
@import "../Page/Group/index.scss";
@import "../Page/Home/index.scss";
@import "../Page/Index/index.scss";
@import "../Page/Introdure/index.scss";
@import "../Page/Layout/index.scss";
@import "../Page/LeaderBoard/index.scss";
@import "../Page/ListCoinPrice/index.scss";
@import "../Page/ListPackageBonus/index.scss";
@import "../Page/ListPackagePurchased/index.scss";
@import "../Page/Login/index.scss";
@import "../Page/Management/index.scss";
@import "../Page/Notifications/index.scss";
@import "../Page/Organization/index.scss";
@import "../Page/Packet/index.scss";
@import "../Page/Profile/index.scss";
@import "../Page/PurchaseHistory/index.scss";
@import "../Page/Recharge/index.scss";
@import "../Page/Register/register.scss";
@import "../Page/Saving/index.scss";
@import "../Page/Settings/index.scss";
@import "../Page/Support/index.scss";
@import "../Page/TransactionRequest/index.scss";
@import "../Page/WithdrawalHistory/index.scss";
@import "../Page/Staking/index.scss";
@import "../Page/Branch/index.scss";
@import "../Page/Game5DLotre/index.scss";
@import "../Page/GameWinGo/index.scss";
@import "../Page/GameK3Lotre/index.scss";
@import "../Page/Withdraw/index.scss";
