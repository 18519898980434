
.recharge{
  &__collapse {
    padding-left: unset;
    padding-right: unset;
  }
  .ic-wallet{
    width: 16.67px;
    margin-right: 8px;
  }
  .balance{
    margin-top: 18px;
    padding: 0 20px 0 20px;
  }
  .ic-title{
    display:flex;
  }
  .total{
    font-size: 16px;
    font-weight: 400;
  }
  .money{
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
  }
  .payment-method{
    margin-bottom: 10px;
    &-item{
      background: linear-gradient(135deg, #a700ff 0%, #00189a 100%);
      padding: 8px;
      display: block;
      border-radius: 10px;
      overflow: hidden;
      color: #ffffff;
      max-width: 220px;
      button {
        height: 40px;
        font-size: 18px;
        text-transform: uppercase;
        border-radius: 6px;
        border: 1px solid #3498db !important;
      }
      h3{
        font-size: 14px;
        color: #ffffff;
      }
      p{
        font-size: 12px;
        opacity: .7;
      }
    }
    @media (max-width: 500px) {
      .payment-method-item{
        margin: auto;
      }
    }

    .dots {
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }
    
    .dot {
      border: none;
      width: 10px;
      height: 10px;
      background: #c5c5c5;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
    }
    
    .dot:focus {
      outline: none;
    }
    
    .dot.active {
      background: #000;
    }
  }
  .amount-picker{
    margin: 10px 10%;
    display: grid;
    grid-template-columns: 50%50%;
    gap: 5px;
    button:hover, button:focus{
      background-color:#dbc7ea !important;
      border: none !important;
      color: #222222 !important;
    }
  }
  .radio{
    background-color: #1B29FA;
    color: #1B29FA;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .row-center{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
  }
  .method{
    font-weight: 400;
  }
  .input{
    border: 1px solid #1B29FA;
    border-radius:  8px;
    margin-top: 12px;
  }
  .note{
    color: #666666;
    background-color: #fff; 
    border-radius: 8px;
    padding: 15px;
    font-size: 14px;
  }
  .recharge-button{
    background-color: #1B29FA;
    padding: 8px 0 8px 0;
    margin: 0 20px 0 20px;
    width: 90%;
    align-self: center;
    color: white;
    border-radius: 20px;
    font-weight: 600;
    border-width: 0;
    margin-top:36px;
  }
  .info{
    border:1px solid #1B29FA;
    border-radius: 8px;
    margin: 28px 20px 0 20px;
    padding: 17px 12px 17px 12px;

  }
  .row-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .info-title{
    font-weight: 400;
    font-size: 12px;
  }
  .info-value{
    font-weight: 600;
    font-size: 14px;
  }
}