.wrapper [class*="ant-modal-content"] {
  height: fit-content !important;
  border-radius: 0 0 10px 10px;
}

.introduce__content {
  min-height: 400px !important;
}

.close {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border: 1px solid #dbdddd;
  right: 0px;
  top: 0px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(30%, -30%);
  border-radius: 50%;
}

.close > svg {
    stroke-width: 2px;
}

.close > svg path:nth-child(1) {
    stroke-width: 0 !important;
}

.btnConfirm {
    width: 100%;
    height: 50px !important;
    border-radius: 25px !important;
    text-transform: uppercase;
    font-size:1rem !important;
    background: var(--main) !important;
    color: #ffffff;
    font-weight: 500 !important;
}
