.win-go {
  &__link-button{
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 8px;
    color: #222222;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    max-width: 80px;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
 
}
  .time-box {
    margin-top: 70px;
    border-radius: 0.26667rem;
    background: #f94b55 url("/assets/images/time-box.png") no-repeat 50%;
    background-size: auto 100%;
    margin-bottom: 2rem;
    .last-box {
      border-left: 0.02667rem dashed white;
      &::before {
        content: "";
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 1.6rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #f5f5f5;
        transform: translate(-50%, 60%);
      }
      &::after {
        content: "";
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 1.6rem;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f5f5f5;
        transform: translate(-50%, -60%);
      }
    }
    .time-text-top {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #ffffff;
    }
    .time-text-bottom {
      color: #ffffff;
      .item-time {
        padding: 0.33333rem;
        background-color: #da2031;
        font-size: 1.33333rem;
        margin-left: 0.33333rem;
        color: #fff;
        font-weight: 600;
        &:first-child {
          background: linear-gradient(135deg, transparent 0.33333rem, #da2031 0)
            0 0;
        }
        &:last-child {
          background: linear-gradient(
            -45deg,
            transparent 0.33333rem,
            #da2031 0
          );
        }
      }
    }
    .time-set {
      padding: 2px;
      border-radius: 2px;
      border: 1px solid white;
    }
  }
  .ant-card {
    background: #ffffff;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .ant-card-body {
      padding: 0px;
      .mask-box {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        font-weight: 600;
        color: #fff;
        font-size: 8rem;
        span.item {
          display: inline-block;
          border-radius: 0.4rem;
          padding: 0 0.4rem;
          background-color: #fb4e4e;
        }
        .first-item {
          margin-left: calc(50% - 81px);
          margin-top: calc(50% - 125px);
        }
        .second-item {
          margin-left: 20px;
        }
      }
      .color-btn {
        &__card {
          &--group {
            button {
              width: 100%;
              border: none;
            }
            .green-btn {
              background-color: #5cba47;
              border-radius: 0 0.4rem 0 0.4rem;
            }
            .purp-btn {
              background-color: #db5fd1;
              border-radius: 0.26667rem;
            }
            .red-btn {
              background-color: #fb4e4e;
              border-radius: 0.4rem 0 0.4rem 0;
            }
          }
        }
      }
    }
  }
  .ant-card .color-ball {
    background: #f8eae8;
    .active {
      border: none !important;
      .txt {
        width: 3.8rem !important;
        height: 3.8rem !important;
        border-radius: 3.8rem !important;
      }
    }
    .number {
      border: 0.02667rem solid #fb4e4e;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 3.8rem;
      text-align: center;
      .txt {
        // background-image: linear-gradient(
        //   to bottom right,
        //   #fb4e4e 50%,
        //   #eb43dd 0
        // ) !important;
        display: block;
        width: 3.46667rem;
        height: 3.46667rem;
        border-radius: 3.46667rem;
        line-height: 3.46667rem;
        color: #fff;
        font-size: 1.61333rem;
      }
    }
  }
  .mutilple-number {
    justify-content: center;
    .bet-mutilple {
      background: #f8f8f8;
      border: 1px solid #74b757;
      border-radius: 4px;
      padding: 0.3rem;
      font-size: 10px;
      line-height: 10px;
    }
    .active {
      background: #74b757;
      color: $white;
    }
  }
  .bet-size {
    width: 100%;
    .bet-big {
      padding: 0.36rem 0.16rem;
      background: #f4b34e;
      border-radius: 16px 0px 0px 16px;
      width: 49%;
    }
    .bet-small {
      padding: 0.36rem 0.16rem;
      background: #74b757;
      border-radius: 0px 16px 16px 0px;
      width: 49%;
    }
  }
  .tab-history {
    div {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0.36rem 0.2rem;
      border-radius: 8px;
      margin-right: 20px;
    }
    .game-history {
      background: rgba(245, 73, 58, 0.1);
      border: 1px solid #f5493a;
      box-shadow: 0px 0px 12px rgba(245, 73, 58, 0.25);
    }
    .figure-history {
      padding: 0.36rem 0.2rem;
      background: rgba(64, 98, 237, 0.1);
      /* Blue 0 */

      border: 1px solid #4062ed;
    }
    .ticket-history {
      padding: 0.36rem 0.2rem;
      background: rgba(64, 98, 237, 0.1);
      /* Blue 0 */

      border: 1px solid #4062ed;
    }
  }
  &__button {
    &__active {
      background: rgba(245, 73, 58, 0.1);
      border: 1px solid #f5493a;
      box-shadow: 0px 0px 12px rgba(245, 73, 58, 0.25);
      border-radius: 8px;
      color: #222222;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 8px 7px;
      cursor: pointer;
    }
    background: rgba(64, 98, 237, 0.1);
    border: 1px solid #4062ed;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 7px;
    cursor: pointer;
  }
  &__table__history {
    .ant-table-placeholder {
      background-color: #fff;
    }
    .ant-table-container {
      padding: unset;
    }
    &__cricel {
      background: #f8f8f8;
      border: 1px solid #222222;
      width: 20px;
      border-radius: 100px;
      height: 20px;
      margin-right: 2px;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &__second {
        color: rgba(0, 0, 0, 0.5);
        font-size: 9px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        width: 15px;
        height: 15px;
      }
      &__active {
        background: #f5493a;
        color: #fff;
        border: 1px solid #f5493a;
      }
      &__L {
        color: #565acf;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        border: 1px solid #565acf;
        border-radius: 100px;
        padding: 2px 2px;
        height: 15px;
        width: 15px;
        margin-right: 2px;
      }
      &__E {
        color: #f4b34e;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        border: 1px solid #f4b34e;
        border-radius: 100px;
        padding: 2px 2px;
        height: 15px;
        width: 15px;
        margin-right: 2px;
      }
      &__H {
        color: #ffffff;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        background-color: #565acf;
        border: 1px solid #565acf;
        border-radius: 100px;
        padding: 2px 2px;
        height: 15px;
        width: 15px;
        margin-right: 2px;
      }
      &__O {
        color: #fff;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        background-color: #f4b34e;
        border: 1px solid #f4b34e;
        border-radius: 100px;
        padding: 2px 2px;
        height: 15px;
        width: 15px;
        margin-right: 2px;
      }
    }
    &__color {
     .color-value {
      display: block;
      height: 0.46667rem;
      width: 0.46667rem;
      border-radius: 0.46667rem;
      margin: 0 0.23333rem;
     } 
    }
  }
  .ant-table-thead tr th {
    background: #fdebea;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  min-height: 100vh;
  margin-bottom: -100px;
  padding-bottom: 20px;
  .currentAmount {
    margin-top: unset;
  }
  .header {
    height: 328px !important;
    position: relative;
  }
  &__wallet {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: auto;
  }
  &__box {
    padding: 8px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #fefbea;
    border-radius: 12px;
    width: 90%;
    margin: 12px auto;
    .rowSlide{
      display: flex;
      align-items: center;
  }
  &__second{
      position: absolute;
      bottom: -60px;
      min-height: 110px;
      left: 5%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      display: flex;
      padding-left: unset;
      padding-right: unset;
      background-color: #fff;
  }
  &__item{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content:center ;
      align-items: center;
      width: 25%;
      color: #42C178;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      &__active{
          color: #F5493A;
          .win-go__box__down {
              path {
                  fill: #F5493A !important;
              }
          }
      }
  }

    &__time {
      margin-bottom: 6px;
    }
    &__down {
      margin-bottom: 4px;
      path {
        fill: #42c178;
      }
    }
  }
  &__line {
    background: rgba(218, 215, 215, 0.8);
    height: 4px;
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    &__small {
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
    svg {
      position: absolute;
      left: 50%;
      margin-left: -10px;
      top: -7px;
    }
  }
  &__title {
    margin-top: 70px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__result {
    padding: 0px 20px 12px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
      text-align: center;
      &__circle {
        height: 40px;
        width: 40px;
        background: #f8f8f8;
        border: 1px solid #222222;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #222222;
        border-radius: 100%;
        margin-bottom: 6px;
        &__second {
          background: #f5493a;
          border: 1px solid #f5493a;
          color: #ffffff;
        }
      }
      &__equal {
        margin-top: 13px;
        color: #222222;
      }
    }
  }
  &__box-number {
    margin: 20px auto;
    width: 90%;
  }
  &__text__active {
    color: #e1584c;
    cursor: pointer;
  }
  &__tab {
    .ant-tabs-tab .ant-tabs-tab-btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #222222;
      background: #eeeeee;
      border-radius: 8px 8px 0px 0px;
      padding: 6px 9px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background: #f5493a;
      border-radius: 8px 8px 0px 0px;
      color: #ffffff !important;
      padding: 6px 9px;
    }
    .ant-tabs-tab {
      padding: unset;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav::before {
      border: 1px solid #eeeeee !important;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0px 0px 0px 4px;
    }
  }

  &__count__box {
    flex-wrap: wrap;
    gap: 5px;
    display: flex;
    justify-content: flex-end;
    &__content {
      color: #42c178;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efeff4;
      width: 20px;
      height: 28px;
    }
  }
  &__terms {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }
  &__wheels {
    padding: 0.26667rem;
    background: #00b977;
    display: inline-block;
    border-radius: 0.18667rem;
    position: relative;
    // margin-top: 0.53333rem;
    text-align: center;

    &__content {
      padding: 0.26667rem;
      background: #00b977;
      display: inline-block;
      border-radius: 0.18667rem;
      position: relative;
      // margin-top: 0.53333rem;
      &::before {
        position: absolute;
        top: 31.5px;
        width: 5px;
        height: 34px;
        content: "";
        background: #008b59;
        left: -9px;
        border-radius: 0.16rem 0 0 0.16rem;
      }
      &::after {
        position: absolute;
        top: 31.5px;
        width: 5px;
        height: 34px;
        content: "";
        background: #008b59;
        right: -9px;
        border-radius: 0 0.16rem 0.16rem 0;
      }
      &__box {
        padding: 0.16rem 0;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#003b26),
          to(#005226)
        );
        background: linear-gradient(180deg, #003b26, #005226);
        border-radius: 0.08rem;
        display: inline-block;
        position: relative;
        &::before {
          position: absolute;
          top: 37px;
          width: 25px;
          height: 15px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          content: "";
          z-index: 3;
          left: -0.02667rem;
          border-right: 20px solid transparent;
          border-left: 20px solid #00b977;
        }
        &::after {
          right: -0.02667rem;
          border-left: 0.53333rem solid transparent;
          border-right: -117.46667rem solid #00b977;
          position: absolute;
          top: 1.09333rem;
          width: 0;
          height: 0;
          border-top: 0.18667rem solid transparent;
          border-bottom: 0.18667rem solid transparent;
          content: "";
          z-index: 3;
        }

        &__slot {
          display: inline-block;
          width: 55px;
          height: 80px;
          border-radius: 0.10667rem;
          overflow: hidden;
          background: #333;
          margin-right: 4px;
          margin-right: 4px;
          vertical-align: bottom;
          position: relative;
          text-align: center;

          &::before {
            // -webkit-box-shadow: inset 0 -0.18667rem 0.10667rem 0 rgb(0 0 0 / 30%), inset 0 0.18667rem 0.10667rem 0 rgb(0 0 0 / 30%);
            // box-shadow: inset 0 -0.18667rem 0.10667rem 0 rgb(0 0 0 / 30%), inset 0 0.18667rem 0.10667rem 0 rgb(0 0 0 / 30%);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 101%;
            height: 101%;
            content: "";
            z-index: 2;
          }
          // &::after{
          //     position: absolute;
          //     top: 1.09333rem;
          //     width: 0;
          //     height: 0;
          //     border-top: 0.18667rem solid transparent;
          //     border-bottom: 0.18667rem solid transparent;
          //     content: "";
          //     z-index: 3;
          // }
          &__colum {
            -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
            touch-action: pan-y;
            &__number {
              background: #00e065;
              color: #fff;
              width: 40px;
              height: 40px;
              line-height: 40px;
              background: #e1e1ec;
              border-radius: 50%;
              font-size: 25px;
              color: rgba(0, 0, 0, 0.4);
              font-weight: 700;
              margin: 0 auto 10px;
            }
            &__number.active {
              background: #00e065;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .red {
    background-color: #fb4e4e !important;
  }
  .green {
    background-color: #5cba47 !important;
  }
  .purple {
    background-color: #db5fd1 !important;
  }
  .line-grad {
    background-image: linear-gradient(
      to bottom right,
      #5cba47 50%,
      #db5fd1 0
    ) !important;
  }
  .five-ball {
    background-image: linear-gradient(to bottom right,#5cba47 50%,#eb43dd 0)!important;
    background-color: #5cba47;
  }
  .zero-ball {
    background-image: linear-gradient(to bottom right,#fb4e4e 50%,#eb43dd 0)!important;
    background-color: #fb4e4e;
  }
  &__model {
    // height: 400px;
    width: 396px !important;
    max-width: 384px !important;
    margin: auto !important;
    top: calc(155px) !important;
    .ant-modal-content {
      // height: 400px !important;
      width: 384px !important;
      max-width: 384px !important;
      margin: auto !important;
      margin-bottom: unset !important;
    }
    .ant-modal-content {
      background: #ffffff;
      border-radius: 24px 24px 0px 0px;
      padding: 24px 0px 0px 0px;
      height: unset !important;
    }
    &-2 {
      top: unset !important;
      .ant-modal-content {
        border-radius: 24px;
        max-width: 350px !important;
        padding: unset !important;
      }
    }
  }
  &__condition {
    &__title {
      background-image: url("../../assets/images/codition.png");
      height: 51px;
      background-size: cover;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
    }
    &__content {
      height: 325px;
      overflow-y: auto;
      font-size: 14px;
      padding: 20px;
    }
  }
}
.modal-game {
  .ant-modal {
    height: auto !important;
    top: calc(100vh - 315px) !important;
    max-width: 384px !important;
    margin: auto !important;
    width: auto !important;
    .ant-modal-content {
      height: auto !important;
      max-width: 384px !important;
      margin: auto !important;
      width: auto !important;
      border-radius: 24px 24px 0px 0px;
      .modal-bet-head {
        padding: 20px 10px;
        background: #e1584c;
        border-radius: 24px 24px 0px 0px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
      .modal-bet-content {
        .bet-amount-grp {
          justify-content: space-between;
          .bet-number-grp {
            font-weight: 400;
            font-size: 10px;
            .bet-number {
              text-align: center;
              padding: 4px 6px;
              background: #eeeeee;
              border-radius: 4px;
            }
            .active {
              background: #74b757;
              color: $white;
            }
            .active-green {
              background: #74b757;
              color: $white;
            }
            .active-red {
              background: #fb4e4e;
              color: $white;
            }
            .active-purple {
              background: #cc69cc;
              color: $white;
            }
          }
        }
        .bet-quantity {
          input {
            text-align: center;
          }
          justify-content: space-between;
          .bet-subtract {
            text-align: center;
            width: 30px;
            height: 30px;
            background: #eeeeee;
          }
          .bet-plus {
            text-align: center;
            width: 30px;
            height: 30px;
            background: #eeeeee;
          }
          input {
            width: 72px;
            height: 30px;
            border-radius: 0px;
          }
        }
      }
      .modal-bet-footer {
        height: 36px;
        color: #ffffff;
        .modal-bet-cancel-btn {
          height: 36px;
          width: 30%;
          background: #25253a;
        }
        .modal-bet-total {
          height: 36px;
          width: 70%;
          background: #e1584c;
        }
      }
    }
    .ant-modal-body {
      height: auto !important;
    }
    .ant-modal-footer {
      border-top: none;
    }
  }
  .red {
    background-color: #fb4e4e !important;
  }
  .green {
    background-color: #5cba47 !important;
  }
  .purple {
    background-color: #db5fd1 !important;
  }
  .line-grad {
    background-image: linear-gradient(
      to bottom right,
      #5cba47 50%,
      #db5fd1 0
    ) !important;
  }
}
